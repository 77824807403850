<script lang="ts" setup>
import { ref, computed, watch, onMounted, getCurrentInstance } from "vue";

import { getApplied, getAllAppliedStatusses, updateApplied, deleteApplied, getNewApplied } from "@/services/api/measure.api";
import { addAppliedMeasure } from "@/services/api/building.api";
import { getEnergyTypes, severityCode } from "@/services/api/energy.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { useUserStore } from "@/stores/user";
import useMessages from "@/composables/messages.js";
import { isEmptyGuid } from "@/composables/miscellaneous.js";
import router from "@/router/index";

const { formatDateIsoD } = useDateTimeFormatter();
const { msgErrorCode } = useMessages();
const userStore = useUserStore();

const props = defineProps({
  // Afhankelijk van de ingang wordt of het appliedId ingevuld (voor wijzigen) of definitionId (voor nieuw)
  appliedId: {
    type: String,
    default: "",
  },
  definitionId: {
    type: String,
    default: "",
  },
  addressId: {
    type: String,
    default: "",
  },
});

const errored = ref(false);
// stel in of velden wijzigbaar zijn (in de frontend)
const readonly = ref(!userStore.hasRoleUser);
const datesReadonly = ref(!userStore.hasRoleUser || userStore.isPartyHome);

const conversionsLoaded = ref(false);
const statussesLoaded = ref(false);
// Gekozen variant alleen voor EML<2023
const variant = ref({
  type: "",
  techPrecon: "-",
  econPrecon: "-",
  indMoment: true,
  natMoment: false,
  indCondition: "-",
  natCondition: "-",
  baseline: "-",
  techniques: "-",
});

// conversiefactoren
const conversions = ref({});
// maatregel
const applied = ref({
  guid: "",
  address: "…",
  definition: {
    kind: {
      key: "",
    },
    category: {
      name: undefined,
      main: {},
    },
    variants: [],
    isApplicableAtIndependentMoment: undefined,
  },
  useAlternative: false,
  description: "", // toelichting
  extDescription: "", // toelichting t.b.v. het RVO
  investment: 0,
  investmentInd: undefined,
  investmentNat: 0,
  recoupedYearsInd: undefined,
  recoupedYearsNat: 0,
  reductionElec: undefined,
  reductionGas: undefined,
  reductionHeat: undefined,
  reductionTotalGJ: 0,
  reductionKgCo2: 0,
  status: { desc: "Geen", key: "None" },
  buildingFunctionCode: "",
  buildingFunctionName: "",
  variantType: undefined,
  variantBase: "",
  proposalDate: { type: Date },
  plannedDate: { type: Date },
  achievedDate: { type: Date },
});

const conditions = ref([]);
// Voor het logboek
const statusses = ref([]);

//component
const reductionElec = ref([]);

onMounted(() => {
  // stel conversiefactoren in
  loadConversion();

  // haal de statussen t.b.v. dropdown op
  getStatusses();

  // Fixt dat het scherm soms halvewege blijft hangen
  window.scrollTo(0, 0);

  reductionElec.value.setCustomValidity("Voer minimaal Reductie elektra, gas of warmte in.");
});

const isNew = computed(() => applied.value.guid == "" || isEmptyGuid(applied.value.guid));

const masterDataLoaded = computed(() => conversionsLoaded.value && statussesLoaded.value);

const investmentAndRecoupedYearsRequired = computed(() => {
  // ToDo: Het volgende nog uitzoeken:
  // De dubbele ontkenning (!!) is bewust. Als het veld initieel leeg is is deze "undefined", als je het leeg maakt wordt deze <empty-string>.
  // Vreemd genoeg levert de check applied.value.investmentInd in development in beide gevallen false op, maar in test levert de <empty-string> true op.
  // Met de dubbele ontkenning gaat het wel goed.
  return (
    (applied.value.status.key == "Applicable" || applied.value.status.key == "ApplicableButPartlyExecuted" || applied.value.status.key == "Executed") &&
    (!!applied.value.investmentInd || !!applied.value.recoupedYearsInd || !!applied.value.reductionElec || !!applied.value.reductionGas || !!applied.value.reductionHeat)
  );
});
const atLeastOneReductionRequired = computed(() => investmentAndRecoupedYearsRequired.value && !applied.value.reductionElec && !applied.value.reductionGas && !applied.value.reductionHeat);
const doesNotMetConditions = computed(() => applied.value.status.key === "InvalidConditions");

// Tijdens de fetchData wordt de updateCarbonDioxide uitgevoerd. Deze updateCarbonDioxide heeft de conversiefactoren nodig die tijdens de Create door loadConversions werden opgehaald.
// Het probleem deed zich regelmatig voor dat de loadConversions nog niet op tijd klaar was voor de fetchData en dat de conversiefactoren nog niet bekend waren in de updateCarbonDioxide.
// Om dat te voorkomen wordt de fetchData nu i.p.v. tijdens de Create uitgevoerd in een watch op masterDataLoaded welke pas true wordt indien het laden van de conversiefactoren (en tevens de statussen) klaar is.
// Op deze manier wordt dus de volgordelijkheid afgedwongen.

watch(
  () => masterDataLoaded.value,
  () => {
    if (props.appliedId && props.appliedId != "") {
      // Haal bestaande toegepaste maatregel op
      //console.log("AppliedDetails, masterDataLoaded appliedId: ", props.appliedId);
      fetchData();
    } else {
      // Zet een nieuw model op.
      //console.log("AppliedDetails, masterDataLoaded definitionId: ", props.definitionId);
      setupNew();
    }
  },
  { immediate: false }
);
watch(
  () => applied.value.status,
  () => {
    setCustomValidityReductionElec();
  }
);

watch(
  () => [applied.value.reductionElec, applied.value.reductionGas, applied.value.reductionHeat],
  () => {
    setCustomValidityReductionElec();
    updateCarbonDioxide();
  }
);

watch(
  () => applied.value.variantType,
  () => {
    selectVariant();
  }
);

//hack voor vue 2.7
const proxy = getCurrentInstance().proxy;

async function loadConversion() {
  const response = await getEnergyTypes();
  if (response.severity <= severityCode.warning) {
    console.log("loadConversions, response.data : ", response.data);

    const elecCo2factor = response.data.find((x) => x.name.toLowerCase() == "electricity");
    if (elecCo2factor && elecCo2factor.co2factor != 0) {
      conversions.value.elecCo2factor = elecCo2factor.co2factor;
    } else {
      proxy.$toaster.error(`Conversiefactor CO₂ voor elektra is niet bekend of geladen`);
    }
    const gasCo2factor = response.data.find((x) => x.name.toLowerCase() == "gas");
    if (gasCo2factor && gasCo2factor.co2factor != 0) {
      conversions.value.gasCo2factor = gasCo2factor.co2factor;
    } else {
      proxy.$toaster.error(`Conversiefactor CO₂ voor gas is niet bekend of geladen`);
    }
    const heatCo2factor = response.data.find((x) => x.name.toLowerCase() == "heat");
    if (heatCo2factor && heatCo2factor.co2factor != 0) {
      conversions.value.heatCo2factor = heatCo2factor.co2factor;
    } else {
      proxy.$toaster.error(`Conversiefactor CO₂ voor warmte is niet bekend of geladen`);
    }
    const gjCo2factor = response.data.find((x) => x.name.toLowerCase() == "gj");
    if (gjCo2factor && gjCo2factor.co2factor != 0) {
      conversions.value.gjCo2factor = gjCo2factor.co2factor;
    } else {
      proxy.$toaster.error(`Conversiefactor CO₂ voor GJ is niet bekend of geladen`);
    }

    conversions.value.elecKWhfactor = 1;
    const gasKWhfactor = response.data.find((x) => x.name.toLowerCase() == "gas");
    if (gasKWhfactor && gasKWhfactor.kwhfactor != 0) {
      conversions.value.gasKWhfactor = gasKWhfactor.kwhfactor;
    } else {
      proxy.$toaster.error(`Conversiefactor kWh voor gas is niet bekend of geladen`);
    }
    const heatKWhfactor = response.data.find((x) => x.name.toLowerCase() == "heat");
    if (heatKWhfactor && heatKWhfactor.kwhfactor != 0) {
      conversions.value.heatKWhfactor = heatKWhfactor.kwhfactor;
    } else {
      proxy.$toaster.error(`Conversiefactor kWh voor warmte is niet bekend of geladen`);
    }
    const gjKWhfactor = response.data.find((x) => x.name.toLowerCase() == "gj");
    if (gjKWhfactor && gjKWhfactor.kwhfactor != 0) {
      conversions.value.gjKWhfactor = gjKWhfactor.kwhfactor;
    } else {
      proxy.$toaster.error(`Conversiefactor kWh voor GJ is niet bekend of geladen`);
    }

    conversionsLoaded.value = true;
  }
}

async function getStatusses() {
  // statusses t.b.v. dropdown
  const statussesResponse = await getAllAppliedStatusses();

  if (statussesResponse && !statussesResponse.error) {
    statussesResponse.keyDescList.sort((a, b) => (a.descr.toLowerCase() > b.descr.toLowerCase() ? 1 : -1));
    statusses.value = statussesResponse.keyDescList;
    //console.log("appliedDetails, getStatusses: ", statusses.value);
    statussesLoaded.value = true;
  } else {
    errored.value = true;
  }
}

async function fetchData() {
  const response = await getApplied(props.appliedId);
  if (response && !response.error) {
    applied.value = { ...response.data };
    // console.log("%cAppliedDetails, fetchData", "background: purple; color: white", response.data);
    formatDates();

    updateCarbonDioxide();
  } else {
    errored.value = true;
  }
}

async function setupNew() {
  // Haal definitie op en vul varianten en statussen
  const newApplied = await getNewApplied(props.definitionId);
  //console.log("AppliedDetails setupNew ", newApplied);

  // Nieuw model instellen.
  applied.value = { ...newApplied.data };

  // Selecteer automatisch de eerste variant indien er maar 1 is
  // if (applied.value.variants.length == 1) {
  //   selectVariant(applied.value.variants[0]);
  // }
}

async function onUpdate() {
  if (!readonly.value) {
    // Kopie van applied maken zodat eventuele watches op het origineel niet afgaan.
    let appliedCopy = { ...applied.value };

    appliedCopy.definitionId = applied.value.definition.id;
    appliedCopy.proposalDate = applied.value.proposalDate !== "" ? applied.value.proposalDate : undefined;
    appliedCopy.plannedDate = applied.value.plannedDate !== "" ? applied.value.plannedDate : undefined;
    appliedCopy.achievedDate = applied.value.achievedDate !== "" ? applied.value.achievedDate : undefined;
    appliedCopy.investmentInd = applied.value.investmentInd !== "" ? applied.value.investmentInd : undefined;
    appliedCopy.recoupedYearsInd = applied.value.recoupedYearsInd !== "" ? applied.value.recoupedYearsInd : undefined;
    appliedCopy.reductionElec = applied.value.reductionElec !== "" ? applied.value.reductionElec : undefined;
    appliedCopy.reductionGas = applied.value.reductionGas !== "" ? applied.value.reductionGas : undefined;
    appliedCopy.reductionHeat = applied.value.reductionHeat !== "" ? applied.value.reductionHeat : undefined;

    if (isNew.value) {
      // Nieuwe maatregel opslaan
      try {
        await addAppliedMeasure(props.addressId, appliedCopy);
        proxy.$toaster.success("Maatregel opgeslagen");
        router.go(-1);
      } catch (e) {
        proxy.$toaster.error(`Fout bij opslaan maatregel (${e})`);
      }
    } else {
      // Bestaand maatregel wijzigen
      appliedCopy.variantType = conditions.value.toString();
      appliedCopy.definitionId = appliedCopy.definition.id;

      const response = await updateApplied(appliedCopy);
      console.log("AppliedDetails onUpdate ", response);
      if (response) {
        switch (response.data.code) {
          case "Success": {
            proxy.$toaster.success("Wijzigingen opgeslagen");
            router.go(-1);
            break;
          }

          case "NoContent": {
            proxy.$toaster.warning("Geen wijzigingen opgeslagen");
            router.go(-1);
            break;
          }

          case "Conflict": {
            proxy.$toaster.error(`Opslaan is mislukt. ` + msgErrorCode(response.data.errorCode));
            break;
          }

          default:
            proxy.$toaster.error(`Fout bij doorvoeren wijziging (${response.data})`);
            break;
        }
      } else {
        proxy.$toaster.error(`Fout bij voltooien update api call`);
      }
    }
  }
}

function onCancel() {
  router.go(-1);
  if (!readonly.value) {
    proxy.$toaster.warning("Maatregel wijzigen geannuleerd.");
  }
}

async function onDelete() {
  if (!readonly.value) {
    if (confirm("Weet u zeker dat u deze aanvullende maatregel wilt verwijderen?") == true) {
      const response = await deleteApplied(props.appliedId);
      if (response) {
        console.log("AppliedDetails onDelete ", response);
        if (response.data == "Success" || response.data == "NoContent") {
          proxy.$toaster.info("Aanvullende maatregel verwijderd");
          router.go(-1);
        } else {
          proxy.$toaster.error(`Fout bij doorvoeren wijziging (${response.data})`);
        }
      } else {
        proxy.$toaster.error(`Fout bij voltooien delete api call`);
      }
    }
  }
}

function selectVariant() {
  if (applied.value.definition.kind.key == "EML") {
    //console.log("AppliedDetails selectVariant ", applied.value.definition.variants);
    if (!applied.value.variantType) {
      applied.value.variantType = applied.value.definition.variants[0].type;
    }

    // omdat EML varianten tbv EML2023 gesplits zijn gaan we ze hier weer samenvoegen

    variant.value.type = applied.value.variantType;

    const typeFilter = applied.value.definition.variants.filter((el) => el.type === applied.value.variantType);
    //console.log("typeFilter ", applied.value.variantType, typeFilter);

    const nat = typeFilter.filter((el) => el.conditionType.key === "NaturalEconomic");
    variant.value.natMoment = nat.length > 0;
    if (variant.value.natMoment) {
      variant.value.natCondition = nat[0].condition;
      variant.value.econPrecon = nat[0].condition;
    }
    const ind = typeFilter.filter((el) => el.conditionType.key === "IndependentEconomic");
    variant.value.indMoment = ind.length > 0;
    if (variant.value.indMoment) {
      variant.value.indCondition = ind[0].condition;
    }
    const tech = typeFilter.filter((el) => el.conditionType.key === "Technical");
    if (tech.length > 0) {
      variant.value.techPrecon = tech[0].condition;
    }
    if (typeFilter.length > 0) {
      variant.value.techniques = typeFilter[0].techniques;
    }
    //console.log("variant ", variant.value);
  } else {
    conditions.value = applied.value.variantType.split(",");
  }
}

function updateCarbonDioxide() {
  const reductionKgCo2 =
    calcCo2(applied.value.reductionElec, conversions.value.elecCo2factor) +
    calcCo2(applied.value.reductionGas, conversions.value.gasCo2factor) +
    calcCo2(applied.value.reductionHeat, conversions.value.heatCo2factor);

  const reductionTotalGJ =
    calcGJ(applied.value.reductionElec, conversions.value.elecKWhfactor, conversions.value.gjKWhfactor) +
    calcGJ(applied.value.reductionGas, conversions.value.gasKWhfactor, conversions.value.gjKWhfactor) +
    calcGJ(applied.value.reductionHeat, conversions.value.heatKWhfactor, conversions.value.gjKWhfactor);

  applied.value.reductionKgCo2 = reductionKgCo2.toFixed(2);
  applied.value.reductionTotalGJ = reductionTotalGJ.toFixed(2);
}

function calcCo2(value, factor) {
  return !isNaN(parseFloat(value)) && !Number.isNaN(factor) ? value * factor : 0;
}

function calcGJ(value, kwhfactor, gjfactor) {
  return !isNaN(parseFloat(value)) && !Number.isNaN(kwhfactor) && !Number.isNaN(gjfactor) ? (value * kwhfactor) / gjfactor : 0;
}

function formatDates() {
  // Tijdcomponent van datum halen
  applied.value.proposalDate = formatDateIsoD(applied.value.proposalDate);
  applied.value.plannedDate = formatDateIsoD(applied.value.plannedDate);
  applied.value.achievedDate = formatDateIsoD(applied.value.achievedDate);
}

function setCustomValidityReductionElec() {
  // Onder boven gedefineerde condities is één van de drie reduction-velden verplicht. Om dit duidelijk te maken wordt de invalid-message op het eerste veld (reductionElec) gewijzigd.
  // Als vervolgens minimaal één van deze velden gevuld wordt dient deze invalid-message weer gereset te worden.
  if (atLeastOneReductionRequired.value) {
    reductionElec.value.setCustomValidity("Voer minimaal Reductie elektra, gas of warmte in.");
  } else {
    reductionElec.value.setCustomValidity("");
  }
}
</script>

<template>
  <v-container fluid class="general-overview applied-details">
    <div v-if="!errored">
      <v-row>
        <v-col class="app-welcome-message">
          <h1 v-if="!readonly">Maatregel bewerken</h1>
          <h1 v-else>Maatregel</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p class="text-h4">{{ applied.address }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <label>Categorie</label>
          <!-- <p v-if="applied.definition && applied.definition.category && applied.definition.category.type.descr"> -->
          <p v-if="applied?.definition?.category?.type?.descr">{{ applied.definition.category.type.descr }} ({{ applied.definition.category.mainType.descr }})</p>
          <p v-else>-</p>
        </v-col>
        <v-col cols="2">
          <label>Code</label>
          <p>{{ applied.definition.code }}</p>
        </v-col>
        <v-col cols="3">
          <label>Soort</label>
          <p>{{ applied.definition.kind.descr }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5">
          <label>Naam</label>
          <p>{{ applied.definition.name }}</p>
        </v-col>
        <v-col cols="7">
          <label>Omschrijving</label>
          <p>{{ applied.definition.description }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="5">
          <label>Uitgangssituatie</label>
          <p v-if="applied.definition.baseline">{{ applied.definition.baseline }}</p>
          <p v-else>-</p>
        </v-col>
        <v-col cols="7">
          <label>Uitvoerbaar op zelfstandig moment</label>
          <p>{{ applied.definition.isApplicableAtIndependentMoment === true ? "Ja" : "Nee" }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <label>Doelmatig beheer en onderhoud (DBO)</label>
          <p v-if="applied.definition.specialCircumstances">{{ applied.definition.specialCircumstances }}</p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <v-row class="overview-wrapper">
        <v-col>
          <form id="update-applied-form" @submit.prevent="onUpdate" class="inner">
            <div class="variant form-group" v-if="applied.definition.alternativeName">
              <v-row>
                <v-col>
                  <v-checkbox class="ma-0 pa-0" light v-model="applied.useAlternative" hide-details label="Alternatieve maatregel gebruikt"></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5">
                  <label>Naam</label>
                  <p>{{ applied.definition.alternativeName }}</p>
                </v-col>
                <v-col cols="7">
                  <label>Omschrijving</label>
                  <p>{{ applied.definition.alternativeDescription }}</p>
                </v-col>
              </v-row>
            </div>

            <v-row class="form-group">
              <v-col cols="4">
                <label>Status</label>
                <v-select
                  class="pt-1"
                  v-model="applied.status"
                  :items="statusses"
                  item-text="descr"
                  item-value="key"
                  label="Status"
                  return-object
                  :disabled="readonly"
                  single-line
                  hide-details
                  required
                  outlined
                  :rules="[(v) => !!v || 'Selecteer status']"
                />
              </v-col>
            </v-row>

            <template v-if="applied.definition.kind.key != 'EML'">
              <!-- tijdelijk :) aanvullende maatregelen hadden voorheen een variant/voorwaarde met de 2023 is deze omgedraaid 
                en wordt aangegeven waaraan ze NIET meer voldoen. Dat klopt dus niet, tijdelijk uitgezet dat conditions alleen voor 2023 gelden
                Mogelijke oplossing is om voor de 2023 standaard allemaal aan te vinken dat ze voldoen dat je moet uitvinken waaraan ze niet voldoen
              -->
              <template v-if="applied.definition.kind.key == 'EML2023'">
                <v-row class="variant form-group" :class="{ disabled: !doesNotMetConditions }" v-if="applied.definition.variants.length > 0">
                  <v-col>
                    <label>Randvoorwaarden waaraan niet voldaan wordt</label>
                    <v-row v-for="(itm, index) in applied.definition.variants" :key="index" :value="itm.type">
                      <v-col cols="auto">
                        <v-checkbox
                          class="ma-0 pa-0"
                          light
                          :disabled="!doesNotMetConditions"
                          v-model="conditions"
                          multiple
                          hide-details
                          :label="`${itm.condition} (${itm.conditionType.descr})`"
                          :value="itm.type"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </template>
            </template>
            <template v-else>
              <v-row class="variant form-group">
                <v-col>
                  <v-row>
                    <v-col cols="4">
                      <label>Variant van toepassing</label>
                      <div>
                        <v-btn-toggle v-model="applied.variantType" group :disabled="readonly">
                          <!-- Voor EML2023 zijn varianten van EML gesplits, hier unieke index voor type opealen-->
                          <v-btn v-for="(itm, index) in Array.from(new Set(applied.definition.variants.map((a) => a.type)))" :key="index" :value="itm">
                            {{ itm }}
                          </v-btn>
                        </v-btn-toggle>
                      </div>
                    </v-col>
                    <v-col cols="4" v-if="applied.variantType != ''">
                      <label>Natuurlijk moment mogelijk</label>
                      <v-checkbox class="ma-0 pa-0" disabled v-model="variant.natMoment" hide-details :label="variant.natCondition"></v-checkbox>
                    </v-col>
                    <v-col cols="4" v-if="applied.variantType != ''">
                      <label>Zelfstandig moment mogelijk</label>
                      <v-checkbox class="ma-0 pa-0" disabled v-model="variant.indMoment" hide-details :label="variant.indCondition"></v-checkbox>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="4">
                      <label>Uitgangssituatie obv een referentietechniek</label>
                      <p>{{ variant.techniques }}</p>
                    </v-col>
                    <v-col cols="4">
                      <label>Economische randvoorwaarde(n)</label>
                      <p>{{ variant.econPrecon }}</p>
                    </v-col>
                    <v-col cols="4">
                      <label>Technische randvoorwaarde(n)</label>
                      <p>{{ variant.techPrecon }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </template>

            <v-row class="form-group">
              <v-col cols="4">
                <label>Investering &euro;</label>
                <input
                  type="number"
                  step="1"
                  min="0"
                  :disabled="readonly"
                  :required="investmentAndRecoupedYearsRequired"
                  class="form-control"
                  name="investment"
                  placeholder="Investering"
                  v-model="applied.investmentInd"
                />
              </v-col>
              <v-col cols="4">
                <label>Terugverdientijd (jaren)</label>
                <input
                  type="number"
                  step="0.1"
                  min="0.00"
                  max="1000"
                  :disabled="readonly"
                  :required="investmentAndRecoupedYearsRequired"
                  class="form-control"
                  name="recoupedYears"
                  placeholder="Terugverdientijd"
                  v-model="applied.recoupedYearsInd"
                />
              </v-col>
            </v-row>

            <!-- -->
            <v-row class="form-group">
              <v-col cols="4">
                <label>Voorsteldatum</label>
                <input
                  type="date"
                  :disabled="datesReadonly"
                  class="form-control"
                  name="proposalDate"
                  v-bind:value="applied.proposalDate"
                  v-on:input="applied.proposalDate = $event.target.value !== '' ? $event.target.value : undefined"
                />
              </v-col>
              <v-col cols="4">
                <label>Datum ingepland</label>

                <input
                  type="date"
                  :disabled="datesReadonly"
                  class="form-control"
                  name="plannedDate"
                  v-bind:value="applied.plannedDate"
                  v-on:input="applied.plannedDate = $event.target.value !== '' ? $event.target.value : undefined"
                />
              </v-col>
              <v-col cols="4">
                <label>Datum uitgevoerd</label>
                <input
                  type="date"
                  :disabled="datesReadonly"
                  class="form-control"
                  name="achievedDate"
                  v-bind:value="applied.achievedDate"
                  v-on:input="applied.achievedDate = $event.target.value !== '' ? $event.target.value : undefined"
                />
              </v-col>
            </v-row>
            <div class="form-group">
              <v-row>
                <v-col cols="4">
                  <label>Reductie elektra (kWh/jaar)</label>
                  <input
                    id="reductionElec"
                    ref="reductionElec"
                    type="number"
                    step="0.01"
                    :disabled="readonly"
                    :required="atLeastOneReductionRequired"
                    class="form-control"
                    name="reductionElec"
                    placeholder="Reductie elektra"
                    v-model="applied.reductionElec"
                  />
                </v-col>
                <v-col cols="4">
                  <label>Reductie gas (m³/jaar)</label>
                  <input
                    type="number"
                    step="0.01"
                    :disabled="readonly"
                    :required="atLeastOneReductionRequired"
                    class="form-control"
                    name="reductionGas"
                    placeholder="Reductie gas"
                    v-model="applied.reductionGas"
                  />
                </v-col>
                <v-col cols="4">
                  <label>Reductie warmte (GJ/jaar)</label>
                  <input
                    type="number"
                    step="0.01"
                    :disabled="readonly"
                    :required="atLeastOneReductionRequired"
                    class="form-control"
                    name="reductionHeat"
                    placeholder="Reductie warmte"
                    v-model="applied.reductionHeat"
                  />
                </v-col>
              </v-row>

              <!-- -->
              <v-row>
                <v-col cols="4">
                  <label>Totale reductie (GJ/jaar)</label>
                  <input class="form-control" disabled name="reductionTotalGJ" placeholder="Reductie totaal" v-model="applied.reductionTotalGJ" />
                </v-col>
                <v-col cols="4">
                  <label>Reductie CO₂ (kg/jaar)</label>
                  <input class="form-control" disabled name="reductionKgCo2" placeholder="Reductie CO₂ totaal" v-model="applied.reductionKgCo2" />
                </v-col>
              </v-row>

              <v-row v-if="userStore.currentPartyType == 'Homes'">
                <v-col cols="4">
                  <label>Warmtebehoefte (kWh/m²)</label>
                  <input class="form-control" disabled name="heatingNeed" placeholder="Warmtebehoefte" v-model="applied.heatingNeed" />
                </v-col>
                <v-col>
                  <label>Energielabel Oud</label>
                  <p>
                    <energy-label :label="applied.energyLabelOld" :estimate="false"></energy-label>
                  </p>
                </v-col>
                <v-col>
                  <label>Energielabel Nieuw</label>
                  <p>
                    <energy-label :label="applied.energyLabelNew" :estimate="false"></energy-label>
                  </p>
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <label>Toelichting</label>
                <v-textarea solo v-model="applied.description" name="description" :disabled="readonly" label=""></v-textarea>
              </v-col>
            </v-row>
            <v-row v-if="applied.definition.isCertifiedMeasure">
              <v-col>
                <label>Informatieplicht verklaring voor RVO (Let op! Dit veld wordt gebruikt in de upload naar het RVO.)</label>
                <v-textarea solo v-model="applied.extDescription" name="extDescription" :disabled="readonly" label=""></v-textarea>
              </v-col>
            </v-row>
            <!-- -->
            <v-row v-if="userStore.hasRoleUser && !readonly">
              <v-col cols="4">
                <v-btn dark type="submit" class="primary" title="save" style="margin-right: 20px">
                  <v-icon dark left>mdi-checkbox-marked-circle</v-icon>
                  Opslaan
                </v-btn>
                <v-btn dark class="primary" title="cancel" @click.prevent="onCancel()">
                  <v-icon dark left>mdi-cancel</v-icon>
                  Annuleren
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4" class="text-right">
                <span class="pa-4">
                  <applied-logging-modal :appliedId="props.appliedId" v-if="!isNew" />
                </span>
                <v-btn v-if="!applied.isCertifiedMeasure && !isNew" dark class="secondary" title="Verwijderen" @click.prevent="onDelete()">
                  <v-icon dark left>mdi-delete-circle</v-icon>
                  Verwijderen
                </v-btn>
              </v-col>
            </v-row>

            <v-row class="form-group" v-else>
              <v-col>
                <v-btn dark class="primary" title="cancel" @click.prevent="onCancel()">
                  <v-icon dark left>mdi-arrow-left</v-icon>
                  Terug
                </v-btn>
              </v-col>
            </v-row>
          </form>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <header class="app-welcome-message">
        <h1>U bent niet geautoriseerd voor deze pagina.</h1>
        <h3>{{ error }}</h3>
      </header>
    </div>
  </v-container>
</template>
